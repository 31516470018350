@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
/* Global Styling */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



h1, h2, h3, h4, h5, h6, p, select, option, textarea, label, input, button, a {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  margin-bottom: 10px;
  
}

/* Page & Content Wrappers */

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  width: 700px;
  text-align: center;
  padding: 20px;
}

@media (max-width: 767px) {
  .contentWrapper {
    width: 100%;
  }
}

.navButtonsWrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 32px;
}

.privacyWrapper {
    margin-top: 32px;
    padding: 0px 10px;
}

.navButtonsWrapper button {
  margin-left: 15px;
}

.navButtonsWrapper button:first-child {
  margin-left: 0px;
}

.resetButtonWrapper {
  margin-top: 20px;
}

.formContentWrapper {
  margin: 50px 0;
  width: 100%;
  text-align: left;
}

form {
  width: 100%;
}
 
/* Main Titles */

.mainTitle {
  font-size: 55px;
  font-weight: 300;
}

.subheading {
  font-size: 40px;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .mainTitle {
    font-size: 38px;
  }
  .subheading {
    font-size: 32px;
    text-align: left;
  }
}

/* Progress Steps */

  /* Defaults */

.stepsContainer {
  display: flex;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.step {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #FFF;
  z-index: 2;
  min-height: 5px;
}

/* .stepIcon {
  background-color: #FFF;
  padding: 15px;
  border: 1px solid #EBE8F0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #B6B4BA;
} */

  /* Current and Previous */

/* .step.current .stepIcon {
  background-color: #2958EB;
  border: 1px solid #2958EB;
  color: #FFF;
}

.step.previous .stepIcon {
  background-color: #2958EB;
  border: 1px solid #2958EB;
  color: #FFF;
} */

  /* Default before/after */

.step:before {
  content:'';
  width: 20px;
  border-top: 10px solid #ECE9F1;
  position: absolute;
  right: 50%;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.step:after {
  content:'';
  width: 20px;
  border-top: 10px solid #ECE9F1;
  position: absolute;
  left: 50%;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

  /* Previous & Current before/after */

.step.previous:before {
  border-top: 10px solid #2958EB;
}

.step.previous:after {
  border-top: 10px solid #2958EB;
}

.step.current:before {
  border-top: 10px solid #2958EB;
}

  /* Border Radius on first/last */

.step:first-child:before {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.step:last-child:after {
border-top-right-radius: 30px;
border-bottom-right-radius: 30px;
}

/* Field Error Messages / Validation */

.error {
  display: inline-flex;
  font-size: 12px;
  color: #FF3B30;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

input.form-error {
  border: 1px solid #FF3B30;
}
input:focus.form-error {
  border: 1px solid #FF3B30;
}

/* Information/View More Fields */

.information {
  display: inline-flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.information button {
  font-size: 12px;
  color: #2958EB;
  font-weight: 500;
  background-color: #FFF;
  border: 0;
  outline: 0;
}

.information button:hover {
  cursor: pointer;
}

/* Show Hidden Fields on Manual Entry */

.hidden-fields {
  display: none;
}

.hidden-fields.active {
  display: block;
}

/* Align Text */

.textAlign {
    text-align: center;
}

/* Media Queries */

@media (min-width: 576px) and (max-width: 767px) {
  .contentWrapper {
    width: 80%;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .contentWrapper {
    width: 100%;
  }
  .textAlign {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .navButtonsWrapper {
    flex-direction: column;
  }
  .navButtonsWrapper button {
    margin-left: 0;
  }
  .navButtonsWrapper button:not(:first-child) {
    margin-top: 15px;
  }
}
.component-button {
	background-color: none;
	border: 0;
	padding: 0;
	margin: 0;
	border-radius: 3px;
	display: inline-flex;
	align-items: center;
	min-width: 260px;
	justify-content: center;
    border-radius: 100px;
}

.component-button:focus {
	outline: none;
}

.component-button:hover {
	cursor: pointer;
}

.component-button.navactive {
	background-color: #04c762;
	padding: 15px 25px;
	color: #fff;
	font-weight: 900;
	font-size: 20px;
}

.component-button:not(.next):not(.submit) {
    background-color: transparent;
	padding: 15px 25px;
	color: #979797;
}

.component-button.reset {
	background-color: #fff;
	padding: 15px 25px;
	color: #2958eb;
	font-weight: 500;
}

.component-button.loading {
	background-color: #00a951;
	padding: 15px 25px;
	color: #fff;
}

.component-button:disabled {
	background-color: #ece9f1;
	padding: 15px 25px;
	color: #b6b4ba;
}

.component-button.selected {
	padding: 15px 25px;
	background-color: #2958eb;
	color: #fff;
	border: 1px solid #2958eb;
}

.component-button.deselected {
	padding: 15px 25px;
	background-color: #fff;
	color: #afbff1;
	border: 1px solid #afbff1;
}

.optionButtonInput {
	display: none;
}

.optionButton {
	background-color: #F5F6FE;
	padding: 15px;
	color: #000;
	font-size: 19px;
	min-height: 92px;
    border-radius: 10px;
}

.optionButton:hover {
	cursor: pointer;
}

.optionButtonInput:checked + .optionButton {
	background-color: #2958eb;
	color: #fff;
	font-size: 19px;
	font-weight: 900;
}

.buttonWrapper {
	display: flex;
	flex: 1 0 100%;
	flex-wrap: wrap;
	max-width: 700px;
}

.optionButtonWrapper {
	padding: 0px 10px;
}

.buttonWrapper.justified {
	justify-content: center;
}

.buttonWrapper div {
	display: flex;
	width: 33%;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}

.buttonWrapper label {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	text-align: center;
}

input[type="submit"] {
	background-color: inherit;
	border: 0;
	padding: 0;
	margin: 0;
	border-radius: 3px;
	background-color: #04c762;
	padding: 15px 25px;
	color: #fff;
	min-width: 300px;
	font-weight: 900;
	font-size: 20px;
}

input[type="submit"]:focus {
	outline: none;
}

.mr10 {
	margin-right: 10px;
}

.form-group {
	display: inline-flex;
	flex-direction: column;
	text-align: left;
	padding: 0px 10px;
	margin-bottom: 25px;
}

.form-group.checkbox label {
	display: flex;
	flex-direction: row;
	text-align: left;
	padding-left: 35px;
    margin-top: 10px;
	margin-bottom: 15px;
	position: relative;
}

.form-group.checkbox label:hover {
	cursor: pointer;
}

.form-group.checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.form-group.checkbox .checkbox-custom {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border-radius: 6px;
	background-color: #fff;
	border: 2px solid #e4e3e6;
}

.form-group.checked label .checkbox-custom {
	background-color: #04c762;
	border: 2px solid #04c762;
}

.form-group.hidden-fields {
	display: none;
}

.form-group.hidden-fields.active {
	display: inline-flex;
}

.form-group:last-child {
	margin-bottom: 0px;
}

.form-group label {
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 8px;
}

.form-group.full-input {
	width: 100%;
}

.dobWrapper {
	text-align: center;
}

@media screen and (max-width: 575px) {
    .dobWrapper {
        text-align: left;
    }
}

.form-group.half-input {
	width: 50%;
}

.form-group.dob-day,
.form-group.dob-month,
.form-group.dob-year {
	width: 33%;
}

.form-group.dob-day input,
.form-group.dob-month input,
.form-group.dob-year input {
	min-height: 72px;
}

.form-group .inputWrapper {
	position: relative;
}

.postcodeSearch {
	background-color: #2958eb;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	border: 0;
	outline: 0;
	border-radius: 6px;
	position: absolute;
	right: 4px;
	height: 47px;
	width: 47px;
	top: 4px;
}

.postcodeSearch:disabled {
	background-color: #ecebed;
}

input {
	border-radius: 8px;
	border: 1px solid #dddcdf;
	font-size: 19px;
	padding: 15px;
	width: 100%;
}

input:focus {
	outline: 0;
	border: 1px solid #2958eb;
}

select {
	border-radius: 8px;
	border: 1px solid #dddcdf;
	font-size: 19px;
	padding: 15px;
	width: 100%;
	-webkit-appearance: none;
	appearance: none;
	background: url(/select.svg) 97% / 2% no-repeat #fff;
}

.form-group.half-input select {
	background: url(/select.svg) 97% / 4% no-repeat #fff;
}

.form-group.years input {
	padding-right: 32px;
}

.form-group.years .inputWrapper {
	position: relative;
}

.form-group.months input {
	padding-right: 32px;
}

.form-group.months .inputWrapper {
	position: relative;
}

.form-group.years .inputWrapper:before {
	content: "";
	width: 2px;
	height: 100%;
	border-left: 2px solid #ecebed;
	position: absolute;
	right: 60px;
	top: 1px;
	bottom: 1px;
}
.form-group.years .inputWrapper:after {
	content: "Years";
	width: 58px;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 700;
	font-family: "Roboto", sans-serif;
	right: 4px;
	top: 0px;
	bottom: 0px;
}

.form-group.months .inputWrapper:before {
	content: "";
	width: 2px;
	height: 100%;
	border-left: 2px solid #ecebed;
	position: absolute;
	right: 70px;
	top: 1px;
	bottom: 1px;
}
.form-group.months .inputWrapper:after {
	content: "Months";
	width: 58px;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 700;
	font-family: "Roboto", sans-serif;
	right: 6px;
	top: 0px;
	bottom: 0px;
}

/* Number field arrows - Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Number field arrows - Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/* Media Queries */

@media (max-width: 767px) {
	.buttonWrapper div {
		width: 50%;
		margin-bottom: 10px;
	}
	.form-group.half-input {
		width: 100%;
	}
    .form-group.half-input.years,.form-group.half-input.months {
		width: 50%;
	}
	.navactive {
		order: 2;
		-webkit-order: 2;
		-moz-order: 2;
	}
	.navactive.next, .navactive.submit {
		order: 1;
		-webkit-order: 1;
		-moz-order: 1;
		margin-top: 0;
		margin-bottom: 15px;
	}
}

#selectedAddress {
	padding-right: 50px;
}

.blueBox {
    background-color: #F4F7FE;
    padding: 20px;
}

@media (max-width: 575px) {
	.contentWrapper {
		max-width: 100%;
		overflow-x: hidden;
	}
	.buttonWrapper div {
		width: 100%;
	}
	.optionButton {
		min-height: 72px;
	}
	.formContentWrapper {
		margin: 35px 0 20px 0;
	}
	.navButtonsWrapper {
		margin-top: 20px;
	}
	.form-group:not(:first-child) {
		margin-top: 15px;
	}
	.postcodeSearch {
		height: 42px;
		width: 42px;
		top: 9px;
		right: 6px;
	}
	.full-input, .half-input {
		margin: 10px 0;
	}
	select {
		background: url(/select.svg) 96% / 4% no-repeat #fff;
	}
}


