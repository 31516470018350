/* Global Styling */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



h1, h2, h3, h4, h5, h6, p, select, option, textarea, label, input, button, a {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  margin-bottom: 10px;
  
}

/* Page & Content Wrappers */

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  width: 700px;
  text-align: center;
  padding: 20px;
}

@media (max-width: 767px) {
  .contentWrapper {
    width: 100%;
  }
}

.navButtonsWrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 32px;
}

.privacyWrapper {
    margin-top: 32px;
    padding: 0px 10px;
}

.navButtonsWrapper button {
  margin-left: 15px;
}

.navButtonsWrapper button:first-child {
  margin-left: 0px;
}

.resetButtonWrapper {
  margin-top: 20px;
}

.formContentWrapper {
  margin: 50px 0;
  width: 100%;
  text-align: left;
}

form {
  width: 100%;
}
 
/* Main Titles */

.mainTitle {
  font-size: 55px;
  font-weight: 300;
}

.subheading {
  font-size: 40px;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 32px;
}

@media (max-width: 767px) {
  .mainTitle {
    font-size: 38px;
  }
  .subheading {
    font-size: 32px;
    text-align: left;
  }
}

/* Progress Steps */

  /* Defaults */

.stepsContainer {
  display: flex;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.step {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #FFF;
  z-index: 2;
  min-height: 5px;
}

/* .stepIcon {
  background-color: #FFF;
  padding: 15px;
  border: 1px solid #EBE8F0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #B6B4BA;
} */

  /* Current and Previous */

/* .step.current .stepIcon {
  background-color: #2958EB;
  border: 1px solid #2958EB;
  color: #FFF;
}

.step.previous .stepIcon {
  background-color: #2958EB;
  border: 1px solid #2958EB;
  color: #FFF;
} */

  /* Default before/after */

.step:before {
  content:'';
  width: 20px;
  border-top: 10px solid #ECE9F1;
  position: absolute;
  right: 50%;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.step:after {
  content:'';
  width: 20px;
  border-top: 10px solid #ECE9F1;
  position: absolute;
  left: 50%;
  width: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

  /* Previous & Current before/after */

.step.previous:before {
  border-top: 10px solid #2958EB;
}

.step.previous:after {
  border-top: 10px solid #2958EB;
}

.step.current:before {
  border-top: 10px solid #2958EB;
}

  /* Border Radius on first/last */

.step:first-child:before {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.step:last-child:after {
border-top-right-radius: 30px;
border-bottom-right-radius: 30px;
}

/* Field Error Messages / Validation */

.error {
  display: inline-flex;
  font-size: 12px;
  color: #FF3B30;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

input.form-error {
  border: 1px solid #FF3B30;
}
input:focus.form-error {
  border: 1px solid #FF3B30;
}

/* Information/View More Fields */

.information {
  display: inline-flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.information button {
  font-size: 12px;
  color: #2958EB;
  font-weight: 500;
  background-color: #FFF;
  border: 0;
  outline: 0;
}

.information button:hover {
  cursor: pointer;
}

/* Show Hidden Fields on Manual Entry */

.hidden-fields {
  display: none;
}

.hidden-fields.active {
  display: block;
}

/* Align Text */

.textAlign {
    text-align: center;
}

/* Media Queries */

@media (min-width: 576px) and (max-width: 767px) {
  .contentWrapper {
    width: 80%;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .contentWrapper {
    width: 100%;
  }
  .textAlign {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .navButtonsWrapper {
    flex-direction: column;
  }
  .navButtonsWrapper button {
    margin-left: 0;
  }
  .navButtonsWrapper button:not(:first-child) {
    margin-top: 15px;
  }
}